import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ViewportBreakpoints } from '../cssConstants';

import ResponsiveContainer from '../layout/ResponsiveContainer';
import NonStretchedImage from '../layout/NonStretchedImage';

const DiagonalStripes = styled.div`
  display: none;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: initial;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
  }
`;

const DiagonalStripe = styled.span`
  display: inline-block;
  position: absolute;
  box-shadow: 0 0 100px 0 rgba(71,91,104,0.2);
  width: 150%;
  height: 350px;
`;

const DarkStripe = styled(DiagonalStripe)`
  background-color: rgba(13, 81, 255, 0.5);
  transform: rotate(-45deg);
  left: 500px;
`;

const LightStripe = styled(DiagonalStripe)`
  background-color: ${({ theme: { colors } }) => colors.white};
  transform: rotate(45deg);
  left: 300px;
`;

const Testimonials = styled.div`
  padding: 40px 0;
  position: relative;
  overflow: visible;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 120px 0;
  }
`;

const TestimonialsWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.grey10};
`;

const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const QuoteArea = styled.div`
  flex: 1 1 50%;

  > p {
    margin: 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > p {
      margin: 40px 0;
      padding-right: 60px;
    }
  }
`;

const ImageArea = styled.div`
  flex: 1 1 50%;

  margin: 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;

    > div {
      margin: 0 auto;
    }
  }
`;

const SingleTestimonial = ({ children, image, imageAlt }) => (
  <TestimonialsWrapper>
    <ResponsiveContainer>
      <Testimonials>
        <DiagonalStripes>
          <LightStripe />
          <DarkStripe />
        </DiagonalStripes>

        <Testimonial>
          <QuoteArea>
            {children}
          </QuoteArea>

          <ImageArea>
            <NonStretchedImage fluid={image} alt={imageAlt} />
          </ImageArea>
        </Testimonial>
      </Testimonials>
    </ResponsiveContainer>
  </TestimonialsWrapper>
);

SingleTestimonial.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.shape({}).isRequired,
  imageAlt: PropTypes.string,
};

SingleTestimonial.defaultProps = {
  imageAlt: '',
};

export default SingleTestimonial;
