import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Element = styled.div`
  width: ${({ width }) => `${width}px`};
  height: ${({ width }) => `${width}px`};
  border-radius: ${({ width }) => `${width / 2}px`};
  background-color: ${({ theme: { colors } }) => colors.brand10};
  color: ${({ theme: { colors } }) => colors.brand};
  font-size: ${({ width }) => `${width * 1.3}px`};
  font-weight: bold;
  font-family: ${({ theme: { fonts } }) => fonts.Body1.fontFamily};
  text-align: center;
  line-height: ${({ width }) => `${width * 1.65}px`};
  margin-bottom: 20px;
`;

const QuotationMark = ({ width }) => (
  <Element width={width}>&ldquo;</Element>
);

QuotationMark.propTypes = {
  width: PropTypes.number,
};

QuotationMark.defaultProps = {
  width: 40,
};

export default QuotationMark;
